import Collapse from 'bootstrap/js/dist/collapse';

const elements = document.querySelectorAll('[data-bs-toggle="collapse"]');
let config = {
    toggle: false, // prevent toggle on init
};

elements.forEach((el) => {
    new Collapse(el, config);
});
