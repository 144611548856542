import './styles/main.scss';

import './shared/vendor/collapse';
import './shared/vendor/modal';
import { isIE, isStorybook, isPrototype } from './shared/lib/utils';

if (isIE) {
    document.body.classList.add('isIE');
}

if (isPrototype() || isStorybook()) {
    import(/* webpackChunkName: 'prototype' */ './init-prototype');
} else {
    import(/* webpackChunkName: 'site' */ './init-site');
}
